<script>
// https://github.com/surmon-china/vue-awesome-swiper
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

export default {
  components: { swiper, swiperSlide },
  props: {
    list: {
      type: Array,
      required: true
    }
  },
  data() {
    const $self = this;
    return {
      window_w: 1920,
      pageCount: 1,
      swiperOption: {
        // autoplay: true,
        delay: 5000,
        slidesPerView: 1,
        speed: 600,
        direction: 'vertical',
        mousewheel: true,
        initialSlide: 1,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        breakpoints: {
          768: {
            direction: 'horizontal',
            slidesPerView: 1,
          },
        },
        on: {
          slideChange () {
            $self.pageCount = ( this.activeIndex % $self.list.length ) || 0
          }
        },
      }
    }
  },
  mounted() {
    // this.sliderMove(1);
    this.getWindowWidth();
    window.addEventListener('resize', this.getWindowWidth)
  },
  destroyed() {
    window.removeEventListener('resize', this.getWindowWidth)
  },
  methods: {
    getBackground(item) {
      const { image } = item;
      const imageM = item.image_m ? item.image_m : item.image;

      return this.getWindowWidth() < 992 ? imageM : image;
    },
    getWindowWidth() {
      return document.body.clientWidth;
    },
    sliderMove(index) {
      this.$refs.timelineSwiper.swiper.slideTo(index, 1000, false);
    }
  }
};
</script>
<template>
  <div class="position-relative">
    <swiper
      ref="timelineSwiper"
      :class="$style.carousel"
      :options="swiperOption"
      >
      <!-- slides -->
      <swiper-slide
        v-for="(slide, index) in list"
        :key="index"
        :class="[
        slide.class_name,
        $style.carouselSlide,
        {
        [$style.hasMask]: slide.hasMask,
        [$style.hasSpecialBg]: slide.hasSpecialBg,
        [$style.isLogoContent]: slide.isLogoContent,
        }]"
        :style="{ backgroundImage: `url(${ getBackground(slide)})` }"
      >
        <div :class="$style.carouselContent">
          <img :class="$style.contnet" :src="slide.content_image">
        </div>
      </swiper-slide>
      <!-- Optional controls -->
      <!--<div class="swiper-pagination"  slot="pagination"></div>-->
      <!-- <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
      <div class="swiper-scrollbar"   slot="scrollbar"></div> -->
    </swiper>
    <div :class="$style.paginationBox">
      <div :class="$style.paginationList">
        <button
          v-for="(slide, index) in list"
          :key="slide.title"
          :class="[$style.paginationItem, { [$style.isActive]: pageCount === index }]"
          @click="sliderMove(index)"
        >
          <span :class="$style.paginationDot"></span>
          <span :class="$style.paginationText">{{slide.title}}</span>
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="scss" module>
  .carousel {
    min-height: 600px;
    height: calc(100vh - 189px);
    @include touch {
      min-height: auto;
      height: calc(100vh - 180px);
    }
  }
  .carouselSlide {
    background-repeat: no-repeat;
    background-position: center !important;
    background-size: cover !important;
    position: relative;
    background: #000;
    background-repeat: no-repeat;
    z-index: 0;
    @include touch {
      background-position: bottom !important;
    }
    &.hasSpecialBg {
      background-position: bottom right !important;
      background-size: 160vh !important;
      @media (max-height: 795px) {
        background-size: 1200px !important;
      }
      @media (min-height: 1145px) {
        background-size: cover !important;
      }
      @include touch {
        background-size: cover !important;
      }
    }
    &.hasMask {
      &:before {
        @include bg-image-mask(linear-gradient(to left, rgba(0, 0, 0, 0), #000));
        opacity: 0.41;
        position: absolute;
      }
    }
    &.isLogoContent {
      .carouselContent {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0 0 100px 100px rgba(0, 0, 0, 0.3);
        background: rgba(0, 0, 0, 0.3);
        .contnet {
          width: 150px;
        }
      }
    }
    .carouselContent {
      text-align: center;
      position: absolute;
      left: 22.5%;
      top: 30%;
      .contnet {
        width: 11.31vh;
      }
      @media (max-height: 950px) {
        top: 27%;
      }
      @include touch {
        top: 4rem;
        left: 3rem;
      }
    }
  }
  .paginationBox {
    position: absolute;
    left: 3rem;
    top: 55%;
    transform: translateY(-50%);
    z-index: 1;
    @include touch {
      bottom: 0rem;
      top: auto;
      left: 50%;
      transform: translateX(-50%);
    }
    .paginationList {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include touch {
        flex-direction: row;
      }
      .paginationDot {
        width: 12px;
        height: 12px;
        display: inline-block;
        border-radius: 100%;
        border: 1px solid #ad9d8b;
        @include touch {
          margin: 1rem;
        }
      }
      .paginationText {
        @include font(14px, null, 1.12px, 400);
        font-family: 'Palatino', serif;
        color: #ad9d8b;
        padding: 0 1rem;
        display: none;
        @include touch {
          display: none !important;
        }
      }
      .paginationItem {
        background: transparent;
        appearance: none;
        border: 0 solid transparent;
        padding: 0.5rem 0;
        &:focus {
          outline: none;
          box-shadow: unset;
        }
        &:hover, &.isActive {
          .paginationDot {
            background: #ad9d8b;
          }
          .paginationText {
            display: inline;
          }
        }
      }
    }
  }
</style>
