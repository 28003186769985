var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative"},[_c('swiper',{ref:"timelineSwiper",class:_vm.$style.carousel,attrs:{"options":_vm.swiperOption}},_vm._l((_vm.list),function(slide,index){
var _obj;
return _c('swiper-slide',{key:index,class:[
      slide.class_name,
      _vm.$style.carouselSlide,
      ( _obj = {}, _obj[_vm.$style.hasMask] = slide.hasMask, _obj[_vm.$style.hasSpecialBg] = slide.hasSpecialBg, _obj[_vm.$style.isLogoContent] = slide.isLogoContent, _obj )],style:({ backgroundImage: ("url(" + (_vm.getBackground(slide)) + ")") })},[_c('div',{class:_vm.$style.carouselContent},[_c('img',{class:_vm.$style.contnet,attrs:{"src":slide.content_image}})])])}),1),_c('div',{class:_vm.$style.paginationBox},[_c('div',{class:_vm.$style.paginationList},_vm._l((_vm.list),function(slide,index){
      var _obj;
return _c('button',{key:slide.title,class:[_vm.$style.paginationItem, ( _obj = {}, _obj[_vm.$style.isActive] = _vm.pageCount === index, _obj )],on:{"click":function($event){return _vm.sliderMove(index)}}},[_c('span',{class:_vm.$style.paginationDot}),_c('span',{class:_vm.$style.paginationText},[_vm._v(_vm._s(slide.title))])])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }