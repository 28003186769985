<script>
import Layout from '@layouts/main'

import { setComputed } from '@state/helpers'

import HomeSlideshow from "@components/HomeSlideshow";

export default {
  components: { Layout, HomeSlideshow },
  data() {
    return {
      slideshow: {
        "zh-TW": {
          title: "HomeSlideshow.vue",
          items: [
            {
              title: "SPA",
              image: require('@assets/images/slieshow/slieshow_spa.png'),
              image_m: require('@assets/images/slieshow/slieshow_spa_m.jpg'),
              content: "似禪亦靜亦幽，<br>心靈放鬆解憂。",
              content_image: require('@assets/images/slieshow/slieshow_content_spa.svg'),
              class_name: "",
              hasMask: 1,
              hasSpecialBg: 0,
              isLogoContent: 0,
            },
            {
              title: "Shabu",
              image: require('@assets/images/slieshow/slieshow_shabu.jpg'),
              image_m: require('@assets/images/slieshow/slieshow_shabu_m.jpg'),
              content: "究極美味，<br>旨味三昧。",
              content_image: require('@assets/images/slieshow/slieshow_content_shabu.svg'),
              class_name: "",
              hasMask: 0,
              hasSpecialBg: 1,
              isLogoContent: 0,
            },
            {
              title: "Café",
              image: require('@assets/images/slieshow/slieshow_cafe.jpg'),
              image_m: require('@assets/images/slieshow/slieshow_cafe.jpg'),
              content: "",
              content_image: require('@assets/images/icon/m_one.svg'),
              class_name: "",
              hasMask: 1,
              hasSpecialBg: 0,
              isLogoContent: 1,
            },
          ]
        },
        "en": {
          title: "HomeSlideshow.vue",
          items: [
            {
              title: "SPA",
              image: require('@assets/images/slieshow/slieshow_spa.png'),
              image_m: require('@assets/images/slieshow/slieshow_spa_m.jpg'),
              content: "似禪亦靜亦幽，<br>心靈放鬆解憂。",
              content_image: require('@assets/images/slieshow/slieshow_content_spa.svg'),
              class_name: "",
              hasMask: 1,
              hasSpecialBg: 0,
              isLogoContent: 0,
            },
            {
              title: "Shabu",
              image: require('@assets/images/slieshow/slieshow_shabu.jpg'),
              image_m: require('@assets/images/slieshow/slieshow_shabu_m.jpg'),
              content: "究極美味，<br>旨味三昧。",
              content_image: require('@assets/images/slieshow/slieshow_content_shabu.svg'),
              class_name: "",
              hasMask: 0,
              hasSpecialBg: 1,
              isLogoContent: 0,
            },
            {
              title: "Café",
              image: require('@assets/images/slieshow/slieshow_cafe.jpg'),
              image_m: require('@assets/images/slieshow/slieshow_cafe.jpg'),
              content: "",
              content_image: require('@assets/images/icon/m_one.svg'),
              class_name: "",
              hasMask: 1,
              hasSpecialBg: 0,
              isLogoContent: 1,
            },
          ]
        },
      },
    }
  },
  computed: {
    ...setComputed,
    slideshowModule() {
      return this.currentLanguage === 'zh-TW' ? this.slideshow['zh-TW'] : this.slideshow['en'];
    },
  },
}
</script>

<template>
  <Layout>
    <section :class="$style.sectionSlideshow">
      <HomeSlideshow v-if="slideshowModule" :list="slideshowModule.items"/>
    </section>
  </Layout>
</template>
<style lang="scss" module>
  .sectionSlideshow {
    padding: 0;
  }
</style>
